/* eslint-disable max-lines-per-function */
import * as FontAwesome from "react-fontawesome";
import * as React from "react";
import * as classNames from "classnames";

import { ConfiguredLink, getLinkFromLinkingSpec } from "../../../utils/linking.util";
import { Site, WithId } from "@maxxton/cms-api";
import { useDispatch, useSelector } from "react-redux";

import { ActivePageManager } from "@maxxton/cms-mxts-api";
import { Button } from "reactstrap";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { CurrentLocale } from "../../../app.types";
import { LinkingSpecOptions } from "../../../inputSpecs/linkingSpec";
import { LocalizeDcAndRcOptions } from "../owner/book/choiceSelection";
import { MyEnvActionType } from "../../../redux/actions/myEnvAction";
import { State } from "../../../redux/index";
import { createWorkOrderReservation } from "./cart.utils";
import { getI18nLocaleString } from "../../../i18n/loader";
import { getSelectedAccoTypeReservedResource } from "../../../redux/reducers/myEnv/myEnv.util";
import { getSelectedAddOnsInsideCart } from "../add-ons/addOns.util";
import { getSelectedMyEnvReservation } from "../../../redux/reducers/myEnv/myEnvState.util";
import namespaceList from "../../../i18n/namespaceList";

interface DynamicButtonLinkingOptions {
    url: string;
    target: string;
    buttonText?: string;
}
interface FallbackCartButtonProps {
    site: Site & WithId;
    currentLocale: CurrentLocale;
}

interface ConfirmAddOnsComponentProps {
    isMyEnvWidget?: boolean;
    context: CMSProviderProperties;
    linking?: LinkingSpecOptions;
    useDynamicSearchButton?: boolean;
    dynamicButtonLinkingOptions?: DynamicButtonLinkingOptions;
    useForWorkOrderReservation?: boolean;
    workOrderLocalizedOptions?: LocalizeDcAndRcOptions[];
}

interface LoaderLabelProps {
    loading?: boolean;
    iconClass?: string;
    label: string;
    context: CMSProviderProperties;
    linking?: LinkingSpecOptions;
    useDynamicSearchButton?: boolean;
    dynamicButtonLinkingOptions?: DynamicButtonLinkingOptions;
}

export function ConfirmAddOnsComponent(props: ConfirmAddOnsComponentProps) {
    const isMyEnvWidget = props.isMyEnvWidget || ActivePageManager.get()?.getPageCategory() === "myEnv";
    if (!isMyEnvWidget) {
        return null;
    }
    return <ConfirmAddOnsBaseComponent {...props} />;
}

export function ConfirmAddOnsBaseComponent(props: ConfirmAddOnsComponentProps) {
    const { context, linking, useDynamicSearchButton, dynamicButtonLinkingOptions, workOrderLocalizedOptions, useForWorkOrderReservation } = props;
    const { currentLocale, site } = context;
    const { dynamicFilter, billState, addOnsState, selectedAddOnsState, myEnvState } = useSelector((state: State) => state);
    const [creatingReservation, setCreatingReservation] = React.useState<boolean>(false);
    const { confirmingShoppingCart } = myEnvState;
    const dispatchAction = useDispatch();
    const selectedAccoTypeReservedResource = getSelectedAccoTypeReservedResource(getSelectedMyEnvReservation({ myEnvState, dispatchAction }), dynamicFilter);
    const selectedAddOnsInCart = useSelector((state: State) =>
        getSelectedAddOnsInsideCart({ selectedAddOnsState: state.selectedAddOnsState, cartReservedResourceId: selectedAccoTypeReservedResource?.reservedResourceId })
    );

    const confirmAddOns = React.useCallback(async () => {
        dispatchAction({
            type: MyEnvActionType.CONFIRM_SHOPPING_CART,
            payload: {},
        });
    }, [dispatchAction]);
    if (!selectedAddOnsInCart.length) {
        return <FallbackCartButton currentLocale={currentLocale} site={site} />;
    }
    if (useForWorkOrderReservation) {
        return (
            <div
                className={classNames("confirm-add-on", `${creatingReservation ? "disabled" : "enabled"}`)}
                onClick={() =>
                    createWorkOrderReservation({
                        setCreatingReservation,
                        workOrderLocalizedOptions,
                        context,
                        myEnvState,
                        dispatchAction,
                        selectedAddOnsState,
                        billState,
                        dynamicFilter,
                        addOnsState,
                    })
                }
            >
                <LoaderLabel
                    loading={creatingReservation}
                    label={getI18nLocaleString(namespaceList.customerBillWidget, "BookWorkOrder", currentLocale, site)}
                    context={context}
                    linking={linking}
                    useDynamicSearchButton={useDynamicSearchButton}
                    dynamicButtonLinkingOptions={dynamicButtonLinkingOptions}
                />
            </div>
        );
    }
    return (
        <div className={classNames("confirm-add-on", `${confirmingShoppingCart ? "disabled" : "enabled"}`)} onClick={confirmAddOns}>
            <LoaderLabel
                loading={confirmingShoppingCart}
                label={getI18nLocaleString(namespaceList.pricePanelWidget, "payYourExtras", currentLocale, site)}
                context={context}
                linking={linking}
                useDynamicSearchButton={useDynamicSearchButton}
                dynamicButtonLinkingOptions={dynamicButtonLinkingOptions}
            />
        </div>
    );
}

function LoaderLabel(props: LoaderLabelProps) {
    const { loading, iconClass, label, context, linking, useDynamicSearchButton, dynamicButtonLinkingOptions } = props;
    const [link, setLink] = React.useState<ConfiguredLink | null>(null);

    React.useEffect(() => {
        async function getLinkUrl() {
            const link = await getLinkFromLinkingSpec({ context, linkingSpecOptions: linking });
            setLink(link);
        }
        getLinkUrl();
    }, []);
    return (
        <Button className="confirm-add-on-button" color="primary" rel={link?.rel}>
            {loading && <FontAwesome name="spinner mr-2" spin />}
            {iconClass && <span className={iconClass} />}
            <span className="confirm-add-on-label">{useDynamicSearchButton ? dynamicButtonLinkingOptions?.buttonText || label : label}</span>
        </Button>
    );
}

export const FallbackCartButton = (props: FallbackCartButtonProps) => {
    const { site, currentLocale } = props;
    return (
        <div className="confirm-add-on disabled">
            <Button className="confirm-add-on-button" disabled={true}>
                <span className="confirm-add-on-label">{getI18nLocaleString(namespaceList.customerBillWidget, "noAddonsAdded", currentLocale, site)}</span>
            </Button>
        </div>
    );
};
