import * as moment from "moment";

import { ApiCallOptions, MxtsApi, PAYER_TYPE, ReservationRequest, ReservationResult, ReservationStatus, ReservationType, ReservationValidationStatus, ReservedResource } from "@maxxton/cms-mxts-api";

import { AddOnsState } from "../../../redux/reducers/add-ons/addOnsReducer";
import { AnyAction } from "redux";
import { BillState } from "../../../redux/reducers/billReducer";
import { CMSProviderProperties } from "../../../containers/cmsProvider.types";
import { DATE_FORMAT } from "../../../utils/constants";
import { Dispatch } from "react";
import { DynamicFilter } from "../../../redux/reducers/dynamicFilter.types";
import { LocalizeDcAndRcOptions } from "../owner/book/choiceSelection";
import { MyEnvState } from "../../../redux/reducers/myEnv/myEnvState";
import { SelectedAddOnsState } from "../../../redux/reducers/add-ons/selectedAddOnsReducer";
import { clearSelectedAddOns } from "../../../redux/actions/add-ons/selectedAddOnsAction";
import { getI18nLocaleString } from "../../../i18n";
import { getLocalizedContent } from "../../../utils/localizedContent.util";
import { getMxtsEnv } from "../../mxts";
import { globalLogger } from "@maxxton/cms-api";
import namespacesList from "../../../i18n/namespaceList";
import { refreshSelectedMyEnvReservation } from "../../../redux/actions/myEnvAction";

interface InsertReserveResourceOptions {
    env: ApiCallOptions;
    selectedAddOnsState: SelectedAddOnsState;
    billState: BillState;
    dynamicFilter: DynamicFilter;
    reservation: ReservationResult;
    addOnsState: AddOnsState;
}

interface WorkOrderReservationOptions {
    setCreatingReservation: (value: React.SetStateAction<boolean>) => void;
    workOrderLocalizedOptions?: LocalizeDcAndRcOptions[];
    context: CMSProviderProperties;
    myEnvState: MyEnvState;
    dispatchAction: Dispatch<AnyAction>;
    selectedAddOnsState: SelectedAddOnsState;
    billState: BillState;
    dynamicFilter: DynamicFilter;
    addOnsState: AddOnsState;
}

async function insertReservedResourcesForWorkOrder(insertReserveResourceOptions: InsertReserveResourceOptions) {
    const { selectedAddOnsState, billState, dynamicFilter, reservation, env, addOnsState } = insertReserveResourceOptions;
    const selectedAddon = selectedAddOnsState?.tempAddOnCarts?.[1]?.selectedAddOns?.[0];
    const selectedAddonBill = billState.myEnvAdditionsBill?.customerBill.find((bill) => bill.resourceId === selectedAddon?.resourceId);
    const addonResource = addOnsState.addOns?.find((addon) => addon.resourceId === selectedAddon?.resourceId);
    if (selectedAddonBill && selectedAddonBill.reservedResource && dynamicFilter.rateType && addonResource) {
        const addonReservedResource: ReservedResource = {
            completed: true,
            endDate: selectedAddonBill.reservedResource.endDate,
            impliesEnabled: true,
            isChoiceAddition: false,
            onBill: true,
            payerType: PAYER_TYPE.CUSTOMER,
            quantity: selectedAddonBill.quantity,
            removable: true,
            reservationId: reservation.reservationId,
            resourceId: selectedAddon.resourceId,
            showPrice: true,
            startDate: reservation.reservationDate,
            status: -1,
            type: selectedAddon.resourceType,
        };
        return MxtsApi.createReservedResource(env, addonReservedResource, [{ key: "reservationId", value: reservation.reservationId }]);
    }
}

export const createWorkOrderReservation = async (workOrderReservationOptions: WorkOrderReservationOptions) => {
    const { setCreatingReservation, workOrderLocalizedOptions, context, myEnvState, dispatchAction, selectedAddOnsState, billState, dynamicFilter, addOnsState } = workOrderReservationOptions;
    const { currentLocale, site } = context;
    try {
        setCreatingReservation(true);
        const env = await getMxtsEnv(context, currentLocale?.code);
        const localizedContent = getLocalizedContent({ site: context.site, currentLocale: context.currentLocale, localizedContent: workOrderLocalizedOptions || [] });
        const customer = await MxtsApi.getCustomer(env, { view: "detail" }, [{ key: "customerId", value: myEnvState.mainCustomer?.customerId }]);
        const mxtsConcern = (await MxtsApi.concern(env, { shortName: env.env.concern })).content[0];

        const reservationDate = moment().format(DATE_FORMAT.MXTS_DATETIME);
        const departureDate = moment().endOf("year").format(DATE_FORMAT.MXTS_DATETIME);

        const reservationRequestBody: ReservationRequest = {
            arrivalInformationStatus: 0,
            completed: false,
            concernId: mxtsConcern.concernId,
            customerId: customer.customerId,
            debitCardInformationStatus: 0,
            eventManagerId: null,
            resortId: myEnvState.selectedReservation?.reservation.resortId,
            exp: false,
            financialStatus: 0,
            initials: null,
            isChanged: false,
            payingCustomerDiffers: true,
            refreshHoldingReport: false,
            status: ReservationStatus.INITIAL,
            tncSigned: false,
            type: ReservationType.INVOICE,
            validationStatus: ReservationValidationStatus.INITIAL,
            distributionChannelId: localizedContent?.distributionChannelId,
            reservationCategoryId: localizedContent?.reservationCategoryId,
            currency: {
                conversionRate: 1,
                multiCurrency: false,
            },
            modifiedDate: reservationDate,
            reservationDate,
            virtualReservationDate: reservationDate,
            arrivalDate: reservationDate,
            departureDate,
            parentId: myEnvState.selectedReservationId,
            paymentTermSetId: myEnvState.selectedReservation?.reservation.paymentTermSetId,
        };

        const newReservation = await MxtsApi.createReservation(env, reservationRequestBody);
        const insertReserveResourceOptions = {
            selectedAddOnsState,
            billState,
            dynamicFilter,
            reservation: newReservation,
            env,
            addOnsState,
        };
        if (newReservation?.reservationId) {
            await insertReservedResourcesForWorkOrder(insertReserveResourceOptions);
            await MxtsApi.patchReservation(env, { status: ReservationStatus.PROVISIONAL }, [{ key: "reservationId", value: newReservation.reservationId }]);
            dispatchAction(refreshSelectedMyEnvReservation(myEnvState.selectedReservationId, false));
            dispatchAction(clearSelectedAddOns({ targetBill: "myEnvAdditionsBill", cartReservedResourceId: selectedAddOnsState.tempAddOnCarts[1].cartReservedResourceId }));
            context.alerts.push({ color: "success", message: getI18nLocaleString(namespacesList.customerBillWidget, "invoiceReservationSuccessful", currentLocale, site) });
        }
    } catch (error) {
        globalLogger.error(error);
        context.alerts.push({ color: "danger", message: getI18nLocaleString(namespacesList.customerBillWidget, "invoiceReservationFailed", currentLocale, site) });
    } finally {
        setCreatingReservation(false);
    }
};
